<template>
  <div class="hold-coontainer">
    <div class="hold-desc">
      <div class="hold-text">掌握情况</div>
      <div class="hold-num">共{{ holdData.cardNum}}张</div>
    </div>
    <div class="figure-box">
      <div id="figureMain" style="width:100%;height:277px"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    holdData: {
      type: Object
    }
  },
  data() {
    return {
      figureData: [],
      myChart: null,
    }
  },
  methods: {
   
    initEcharts() {
      var chartDom = document.getElementById('figureMain');
      this.myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        color: ['#52CCB4', '#FFB700', '#1789FC', '#FF4B3E', 'rgba(32, 32, 32, 0.08)'],
        xAxis: [{
          type: 'category',
          show: true,
          data: ["未学", "记忆中", "已掌握", "疑难", "停学"],
          axisLabel: {
            color: 'rgba(32, 32, 32, 0.4)',
            fontSize: 14,
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            margin: 40,
            align: 'left',
            color: 'rgba(32, 32, 32, 0.4)',
            fontSize: 12,
          },
          splitLine: {     //网格线
            "show": false
          },
          splitNumber: 3,
        }],
        series: [
          {
            data: this.holdData.holdData,
            type: 'bar',
            colorBy: 'data',
            barWidth: 40,
            showBackground: false,
            itemStyle: {
              borderRadius: [4, 4, 0, 0]
            }
          }
        ],
        grid: {
          left: 40,
          top: 10,
          right: 0,
          bottom: 24
        },
        tooltip: {
          trigger: 'axis',
        }
      };
      option && this.myChart.setOption(option)
      // window.addEventListener('resize',()=> {
      //   this.myChart.resize()
      // })
    },
    resizeFigure() {
      this.myChart.resize()
    }
  },
  mounted() {
    this.initEcharts()
  },
}
</script>

 <style lang="scss" scoped>
.hold-coontainer {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 32px;

  .hold-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .hold-text {
      font-size: 20px;
      font-weight: 600;
      color: #00000a;
    }

    .hold-num {
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);
    }
  }

  .figure-box {
    width: 100%;
    height: 327px;
    background-color: #fff;
    border-radius: 12px;
    padding: 26px 24px 24px 24px;
  }
}
</style>