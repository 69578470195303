<template>
  <div class="summary-container">
    <div class="summary-head">
      <div class="summary-head-date">{{ selectTimeTitle() }}</div>
      <div class="summary-head-select-time">
        <el-select v-model="section" @change="selectTime">
          <el-option v-for="item in timeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
    <summary-item :summaryItemData="summaryItemData" v-if="Object.keys(summaryItemData).length > 0"></summary-item>
    <predict-figure ref="figure" :predictData="predictData" v-if="predictData.length > 0"></predict-figure>
    <study-summary
      ref="summary"
      @selectTime="selectTimeTrend"
      :studyTrendData="studyTrendData"
      v-if="Object.keys(studyTrendData).length > 0"
    ></study-summary>
    <hold-condition ref="hold" :holdData="categoryListData" v-if="Object.keys(categoryListData).length > 0"></hold-condition>
  </div>
</template>
<script>
import SummaryItem from "../components/summary/SummaryItem.vue"
import PredictFigure from "../components/summary/PredictFigure.vue"
import StudySummary from "../components/summary/StudySummary.vue"
import HoldCondition from "../components/summary/HoldCondition.vue"
import request from '../libs/api.request'
import { showScrollBar } from "../libs/util"
export default {
  components: {
    SummaryItem,
    PredictFigure,
    StudySummary,
    HoldCondition,
  },
  data() {
    return {
      section: '',
      timeList: [
        {
          label: '历史累计',
          id: ''
        },
        {
          label: '今日',
          id: 'today'
        },
        {
          label: '近7天',
          id: '7day'
        },
        {
          label: '近30天',
          id: '30day'
        },
      ],
      summaryItemData: {},// 今日，近7天...统计的数据
      studyTrendData: {}, //学习统计数据
      predictData: [], //预测的数据
      categoryListData: {}
    }
  },
  methods: {
     getConditionData() {
      request.request({
        url:'/statistics/cardNum'
      }).then(res => {
        this.categoryListData.holdData = [res.newCardNum,res.lrnCardNum,res.handledCardNum,res.hardCardNum,res.stopCardNum]
        this.categoryListData.cardNum = res.cardNum
      })
    },
    // 获取统计数量
    getSummaryData() {
      request.request({
        url: '/statistics/countByDay',
        params: {
          section: this.section
        }
      }).then(res => {
        this.summaryItemData = res
      })
    },
    selectTime(v) {
      this.section = v
      this.getSummaryData()
    },
    // 学习曲线数据
    getStudyTrend(dateType = 'day') {
      request.request(
        {
          url: '/statistics/studyTrend',
          params: {
            dateType
          }
        }
      ).then(res => {
        this.studyTrendData = res
      })
    },
    selectTimeTrend(time) {
      this.getStudyTrend(time)
    },
    getPredictData() {
      request.request(
        {
          url: '/statistics/forecast',
        }
      ).then(res => {
        this.predictData = res
      })
    },
    resizeEchatrs() {
      this.$refs.figure.resizeFigure()
      this.$refs.summary.resizeFigure()
      this.$refs.hold.resizeFigure()
    }
  },
  computed: {
    selectTimeTitle() {
      return function () {
        switch (this.section) {
          case 'today':
            return '今日'
          case '7day':
            return '近7天'
          case '30day':
            return '近30天'
          case '':
            return '历史累计'
        }
      }
    }
  },
  activated() {
    this.getStudyTrend()
    this.getPredictData()
    this.getConditionData()
    showScrollBar()
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeEchatrs)
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener('resize', this.resizeEchatrs)
    }, 200)
  },
  created() {
    this.getSummaryData()
  },
}
</script>
<style lang="scss" scoped>
.summary-container {
  width: 100%;
  padding: 0 26px;
  // height: 100%;
  margin: 0 auto;
  max-width: 1128px;
  .summary-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 69px;
    .summary-head-date {
      font-size: 20px;
      color: #00000a;
      font-weight: 600;
    }

    ::v-deep .el-select {
      .el-input__inner {
        width: 106px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 6px;
        font-size: 14px;
        color: #202020;
        border: 1px solid rgba(32, 32, 32, 0.16);
      }
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
}
</style>