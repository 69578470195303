<template>
  <div class="figure-container">
    <div class="figure-text">预测未来</div>
    <div class="figure-main">
      <div id="myMain1" style="width: 100%;height: 100%;"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    predictData: {
      type: Array
    }
  },
  data() {
    return {
      textList: [],
      numList: [],
      colorList: [],
      myChart: null
    }
  },
  methods: {
    initEcharts() {
      let chartDom = document.getElementById('myMain1');
      // var myChart = this.$echarts.init(chartDom);
      this.myChart = this.$echarts.init(chartDom);

      let option;

      option = {
        xAxis: [{
          type: 'category',
          show: false,
          data: this.textList
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            interval: 0,
            margin: 41,
            align: 'left',
            color: 'rgba(32, 32, 32, 0.4)',
            fontSize: 12,
          },
          "splitLine": {     //网格线
            "show": false
          },
          splitNumber: 3,
        }],
        series: [
          {
            data: this.numList,
            type: 'bar',
            barWidth: 26,
            showBackground: false,
            itemStyle: {
              borderRadius: [4, 4, 0, 0],
              color: (params) => {
                return this.colorList[params.dataIndex]
              }
            },
            label: {
              show: true,
              position: 'top',
              distance: 2,
              fontSize: 12,
              color: '#FF8800',
              formatter: function (params) {
                if (params.name === '今天') {
                  return "今天"
                } else {
                  return ''
                }
              }
            },
          }
        ],
        grid: {
          left: 40,
          top: 10,
          right: 0,
          bottom: 5
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove'
        }
      };
      // option && myChart.setOption(option)
      option && this.myChart.setOption(option)
    },
    resizeFigure() {
      this.myChart.resize()
    }
  },
  created() {
    this.numList = this.predictData.map(v => {
      return v.num
    })
    this.textList = this.predictData.map(v => {
      return v.text
    })
    this.colorList = this.predictData.map(v => {
      return v.color
    })
  },
  mounted() {
    this.initEcharts()
  },
}
</script>

<style lang="scss" scoped>
.figure-container {
  width: 100%;
  margin-top: 48px;

  .figure-text {
    font-size: 20px;
    font-weight: 600;
    color: #00000a;
    margin-bottom: 24px;
  }

  .figure-main {
    width: 100%;
    height: 208px;
    background-color: #fff;
    border-radius: 12px;
    padding: 26px 24px 26px 24px;
  }
}
</style>