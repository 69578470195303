<template>
  <div class="study-summary-container">
    <div class="summary-date-box">
      <div class="summary-text">学习统计</div>
      <div class="date-item">
        <div class="date-day" @click="dateClick('day')" :class="currentIndex == '1' ? 'active' : ''"
          :style="{ borderRight: currentIndex == '2' ? '0' : '' }">天</div>
        <div class="date-week" @click="dateClick('week')" :class="currentIndex == '2' ? 'active' : ''">周</div>
        <div class="date-month" @click="dateClick('month')" :class="currentIndex == '3' ? 'active' : ''"
          :style="{ borderLeft: currentIndex == '2' ? '0' : '' }">月</div>
      </div>
    </div>

    <div class="summary-main">
      <div id="myMain" style="width: 100%;height:275px"></div>
      <div class="summary-main-footer">
        <div class="footer-item study-num"></div>
        <div class="item-text">学习量</div>
        <div class="footer-item edit"></div>
        <div class="item-text">新增卡牌</div>
        <div class="footer-item study-time"></div>
        <div class="item-text">学习时长(分钟)</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    studyTrendData: {
      type: Object
    }
  },
  data() {
    return {
      currentIndex: 1,
      currentTime: 'day',
      studyTime: [],
      myChart: null
    }
  },
  methods: {
    initEcharts() {
      var chartDom = document.getElementById('myMain');
      this.myChart = this.$echarts.init(chartDom);

      var option;
      option = {
        color: ['#FFB700', '#52CCB4', '#1789FC'],
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          show: true,
          axisLabel: {
            color: 'rgba(32, 32, 32, 0.4)',
            fontSize: 14,
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: this.studyTrendData.header,
          splitNumber: 7
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            margin: 40,
            align: 'left',
            color: 'rgba(32, 32, 32, 0.4)',
            fontSize: 12,
          },
          splitNumber: 4,
        }],
        series: [
          {
            data: this.studyTrendData.studyNum, //学习量
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#FFB700'
            }
          },
          {
            data: this.studyTrendData.addCardNum,// 新增卡牌
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#52CCB4'
            },
            symbol: 'none'
          },
          {
            data: this.studyTime, //学习时长
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#1789FC'
            },
          },
        ],
        grid: {
          left: 40,
          top: 6,
          right: 10,
          bottom: 20
        }
      };
      option && this.myChart.setOption(option)

    },
    refreshFigureDate(newData) {
      this.myChart.setOption({
        series: [
          { data: newData.studyNum },
          {
            data: newData.addCardNum
          },
          {
            data: this.studyTime
          },
        ],
        xAxis: [
          {
            data: newData.header
          }
        ]
      })
    },
    dateClick(time) {
      if (this.currentTime === time) return
      if (time === 'day') {
        this.currentIndex = 1
      } else if (time === 'week') {
        this.currentIndex = 2
      } else {
        this.currentIndex = 3
      }
      this.currentTime = time
      this.$emit('selectTime', time)
    },
    resizeFigure() {
      this.myChart.resize()
    }
  },
  watch: {
    studyTrendData(newData) {
      this.studyTime = newData.studyTime.map(v => {
        return Math.ceil(v / 1000 / 60)
      })
      this.refreshFigureDate(newData)
    }
  },
  created() {
    if (this.studyTrendData !== undefined && Object.keys(this.studyTrendData).length > 0) {
      this.studyTime = this.studyTrendData.studyTime.map(v => {
        return Math.ceil(v / 1000 / 60)
      })
    }
  },
  mounted() {
    this.initEcharts()
  }
}
</script>

<style lang="scss" scoped>
.study-summary-container {
  width: 100%;
  margin-top: 48px;

  .summary-date-box {
    display: flex;
    justify-content: space-between;

    .summary-text {
      font-size: 20px;
      font-weight: 600;
      color: #00000a;
      margin-bottom: 24px;
    }

    .date-item {
      width: 116px;
      height: 32px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #202020;

      &:hover {
        cursor: pointer;
      }

      .date-day {
        width: 33%;
        height: 100%;
        line-height: 32px;
        text-align: center;
        border: 1px solid rgba(32, 32, 32, 0.16);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        &.active {
          border-color: #ffb700;
          color: #ffb700;
        }
      }

      .date-week {
        width: 33%;
        height: 100%;
        line-height: 32px;
        text-align: center;
        border-top: 1px solid rgba(32, 32, 32, 0.16);
        border-bottom: 1px solid rgba(32, 32, 32, 0.16);

        &.active {
          border-left: 1px solid #ffb700;
          border-right: 1px solid #ffb700;
          border-color: #ffb700;
          color: #ffb700;
        }
      }

      .date-month {
        width: 33%;
        height: 100%;
        line-height: 32px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        text-align: center;
        border: 1px solid rgba(32, 32, 32, 0.16);

        &.active {
          border-color: #ffb700;
          color: #ffb700;
        }
      }
    }
  }

  .summary-main {
    width: 100%;
    height: 362px;
    background-color: #fff;
    border-radius: 12px;
    padding: 26px 24px 24px 24px;

    .summary-main-footer {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .footer-item {
        width: 14px;
        height: 14px;
        border-radius: 4px;
        margin-right: 8px;

        &.study-num {
          background-color: #ffb700;
        }

        &.edit {
          background-color: #52ccb4;
        }

        &.study-time {
          background-color: #1789fc;
        }
      }

      .item-text {
        font-size: 14px;
        color: rgba(32, 32, 32, 0.6);
        margin-right: 48px;
      }
    }
  }
}
</style>