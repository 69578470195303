<template>
  <div class="summary-box">
    <div class="summary-item">
      <div class="item-img-box">
        <svg-icon iconClass="summary_new_card" class="w-24 h-24"></svg-icon>
      </div>
      <div class="item-info">
        <div class="item-info-num">{{ summaryItemData.newNum }}</div>
        <div class="item-info-text">学习新卡</div>
      </div>
    </div>
    <div class="summary-item">
      <div class="item-img-box">
        <svg-icon iconClass="summary_review_card" class="w-24 h-24"></svg-icon>
      </div>
      <div class="item-info">
        <div class="item-info-num">{{ summaryItemData.revNum }}</div>
        <div class="item-info-text">复习卡片</div>
      </div>
    </div>
    <div class="summary-item">
      <div class="item-img-box">
        <svg-icon iconClass="summary_study_num" class="w-24 h-24"></svg-icon>
      </div>
      <div class="item-info">
        <div class="item-info-num">{{ summaryItemData.studyNum }}</div>
        <div class="item-info-text">学习次数</div>
      </div>
    </div>
    <div class="summary-item">
      <div class="item-img-box">
        <svg-icon iconClass="summary_study_time" class="w-24 h-24"></svg-icon>
      </div>
      <div class="item-info">
        <div class="item-info-num">{{ studyTime() }}</div>
        <div class="item-info-text">学习时长{{text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summaryItemData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      text:'( h )'
    }
  },
  computed: {
    studyTime() {
      return ()=> {
        if(this.summaryItemData.studyTime > 0) {
          if((this.summaryItemData.studyTime /1000 / 60 /60) > 1) {
          this.text = '( h )'
          return (this.summaryItemData.studyTime /1000 / 60 /60).toFixed(2)
          }else {
            this.text = '( m )'
            return (this.summaryItemData.studyTime /1000 / 60).toFixed(2)
          }
        }else {
          this.text = '( h )'
          return 0
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .summary-item {
    display: flex;
    width: 25%;
    height: 94px;
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    &:nth-child(3) {
      margin-right: 24px;
    }
    &:nth-child(2) {
      margin-right: 24px;
      margin-left: 24px;
    }
    .item-img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: #f7f7f7;
      border-radius: 12px;
      margin-right: 20px;
    }
    .item-info {
      .item-info-num {
        font-size: 28px;
        line-height: 24px;
        font-weight: 600;
        color: #202020;
      }
      .item-info-text {
        margin-top: 10px;
        font-size: 12px;
        line-height: 12px;
        color: rgba(32, 32, 32, 0.6);
      }
    }
  }
}
</style>